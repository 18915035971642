<template>
  <div>
    <v-dialog v-model="open" width="410" persistent>
      <v-card style="border-radius: 15px">
        <v-card-title class="pt-6">
          <v-layout justify-center>
            <img
              v-if="type == 'error'"
              class="ml-6"
              width="80"
              height="80"
              src="../assets/icon/error.svg"
            />
            <img
              v-if="type == 'alert'"
              class="ml-6"
              width="80"
              height="80"
              src="../assets/icon/alert.svg"
            />
            <img
              v-if="type == 'success'"
              class="ml-6"
              width="80"
              height="80"
              src="../assets/icon/success.svg"
            />
            <img
              v-if="type != 'success' && type != 'alert' && type != 'error'"
              class="ml-6"
              width="80"
              height="80"
              src="../assets/icon/info.svg"
            />
          </v-layout>
          <v-btn
            @click="close()"
            color="#466BE3"
            icon
            x-small
            style="display: flex; left: 18px; bottom: 45px"
          >
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <p class="login___txt_title">{{ title }}</p>
          <p class="login___txt_sms pb-0 mb-0">{{ text }}</p>
        </v-card-text>

        <v-card-actions class="pb-8">
          <v-row>
            <v-col class="mb-0 pb-0" cols="12">
              <slot name="actions"></slot>
            </v-col>
            <v-col cols="12">
              <v-btn
                block
                color="#466BE3"
                dark
                rounded
                @click="close()"
                class="alert__button"
              >
                CERRAR
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: ["open", "type", "title", "text", "redirect", "params"],
  data() {
    return {
      img: "",
    };
  },
  methods: {
    close() {
      if (this.redirect != "") {
        if (this.params != null) {
          this.$router.push({ name: this.redirect, params: this.params });
        } else {
          this.$router.push({ name: this.redirect });
        }
      }
      this.$emit("close", false);
    },
  },
};
</script>
<style scoped>
.login___txt_title {
  font-family: "AsapRegular";
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 30px;
  text-align: center;
  color: #353535;
}
.login___txt_sms {
  font-family: "ProximaNovaRegular";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  text-align: center;
  color: #898989;
}

.alert__button:hover {
  background-color: #324ca1 !important;
}

@font-face {
  font-family: "AsapRegular";
  src: url("../assets/font/Asap/Asap-Regular.ttf");
}

@font-face {
  font-family: "ProximaNovaRegular";
  src: url("../assets/font/ProximaNova/ProximaNova-Regular.ttf");
}
</style>
